
import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledCarousel } from "reactstrap";
import { Link } from "react-router-dom";
const items = [
  {
    src: require("assets/img/theme/img-1-1200x1000.jpg"),
    altText: "",
    caption: "",
    header: ""
  },
  {
    src: require("assets/img/theme/img-2-1200x1000.jpg"),
    altText: "",
    caption: "",
    header: ""
  },
];

class Carousel extends React.Component {
  render() {
    return (
      <>
        <section className="section section-shaped" >
          
         
          <Container className="py-md mt-5">
            <Row className="justify-content-between align-items-center">
              <Col className="mb-5 mb-lg-0" lg="5">
                <h1 className="text-dark font-weight-light">
                Our Success Stories
                </h1>
                <p className="lead text-dark mt-4">
                At NDOT, we are proud of the successes we have achieved for our clients. Here are some examples of the challenges our clients faced, and the solutions we provided to help them achieve their goals.
                </p>
               {/* <Link to="/customers"> <Button
                  className="btn mt-4"
                  color="primary"
                >
                  See all customers
                </Button></Link> */}
              </Col>
              <Col className="mb-lg-auto" lg="6">
                <div className="rounded shadow-lg overflow-hidden transform-perspective-right">
                  <UncontrolledCarousel items={items} />
                </div>
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
      </>
    );
  }
}

export default Carousel;
