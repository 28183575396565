import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import Carousel from "./Carousel";
import CustomControls from "./CustomControls";
//section 2

class Customers extends React.Component {
  render() {
    return (
      <>
      <Helmet>
        <title>Our Valuable Customers
                                  </title>
        <meta name="description" content="This is a description of my page." />
      </Helmet>
             <DemoNavbar />
             <div className="position-relative">
          <section className="section section-hero section-shaped">
            {/* Background circles */}
            <div className="shape shape-style-1 shape-default">
          
              <img
                      alt="..."
                      className="heroimg img-fluid "
                      src={require("assets/img/brand/heroo.png")}
                    
                    />
            </div>
            <Container className="shape-container d-flex align-items-center py-lg">
              <div className="col px-0">
                <Row className="align-items-center ">
                  <Col className="text-left" lg="6">
                    {/* <img
                      alt="..."
                      className="img-fluid"
                      src={require("assets/img/brand/ndot_logo.png")}
                      style={{ width: "50%" }}
                    /> */}
                    <div className="fronttext mt-5">
                    <h1 style={{color:'#181818de',fontWeight:'600',fontSize:'35px' }} className="hometext ">
                    Delightful customer experiences are the norm at NDOT
                    <span className="text-success">
                     </span>

                    </h1>
                    <Row className="buttonrow">
                      {/* <Col>
                      <Link>
                    <Button color="primary" style={{width:'145px'}}>
                  Case Studies

                  </Button></Link>
                    </Col> */}
                    <Col>
                    <Link to=
                    'contact-us'>

                    <Button color="dark" outline type="button">
                  Contact Us

                  </Button>   
                  </Link>

                    </Col>
                    </Row>
                    </div>
                   
                  </Col>
                </Row>
              </div>
            </Container>
        
          </section>
        </div>
        <section
          className="section section-components pb-0"
          id="section-components"
        >
           <Container >
            <Row className="justify-content-center">
              <Col lg="10">
                {/* section 2*/}
                <h2 className=" font-weight-bold text-dark mb-3 mt-5 " style={{textAlign:'center'}}>
                  <span style={{fontSize:'30px', }}>We empower over 60K+ businesses to delight their customers and employees with ease</span>
                </h2>
                <div>
                <Row style={{alignItems:'center' }}>
                  {/* <Col >
               
                  <img
                      alt="..."
                      className="sectiononeimg img-fluid mt-5"
                      src={("	https://www.ndottech.com/public/frontend/images/success/logo_part1.png")}
                    width={"100%"}
                    style={{marginLeft:'10px'}}
                    />
                    </Col> */}
                  
                 
                  
                  </Row>
                  <Row style={{alignItems:'center' }}>
                  <Col >
               
                  <img
                      alt="..."
                      className="sectiononeimg img-fluid "
                      src={require("assets/img/brand/km.png")}
                    width={"90%"}
                    />
                    </Col>
                    <Col>
                     <img
                      alt="..."
                      className="sectiononeimg img-fluid "
                      src={require("assets/img/brand/shell.png")}
                      width={"50%"}
                    />
                    </Col>
                    <Col>
                     <img
                      alt="..."
                      className="sectiononeimg img-fluid "
                      src={require("assets/img/brand/seac.png")}
                      width={"50%"}
                    />
                    </Col>
                    <Col>
                     <img
                      alt="..."
                      className="sectiononeimg img-fluid "
                      src={require("assets/img/brand/jll.png")}
                      width={"50%"}
                    />
                  
                  </Col>
                 
                  
                  </Row>
                 
                </div>
               
              </Col>
            </Row>
            </Container >
            </section>
            {/* <section
          className="section section-components pb-0 secheight"
          id="section-components"
          style={{backgroundColor:'#f0fff4' }}
        >
                    <Container style={{marginTop:'5%'}}>

            <Row className="justify-content-center">
              <Col lg="12"> */}
                {/* section 2*/}
                
                {/* <div>
                  <Row>
                  <Col  sm="4">
                  <img
                      alt="..."
                      className="sectiontwoimg img-fluid  mt-5"
                      src={require("assets/img/brand/ai2.png")}
                    
                    />
                  </Col>
                  <Col sm="8">
                  <h2 className=" font-weight-bold text-dark  mb-3 mt-5 " >
                  <span style={{fontSize:'40px' }}>Cost Savings
                      </span>
                </h2>
                  <p style={{fontSize:'20px' }}>  Clients can save money on development costs by leveraging the expertise and resources of our OPD team. The team is typically located in our offshore locations in India, where labor costs are lower, providing cost savings compared to developing software in-house.
                  </p>
                  
                  <Button className="btn" color="primary">Read More</Button>
                  </Col>
                
                  
                  </Row>
                  
                </div>
               
              </Col>
            </Row>
            </Container>
            </section>
            <section
          className="section section-components pb-0 secheight"
          id="section-components"
        >
                    <Container style={{marginTop:'5%'}}>
            <Row className="justify-content-center">
              <Col lg="12"> */}
                {/* section 2*/}
                {/* <h2 className=" font-weight-bold text-dark mb-3 mt-5 " >
                  <span style={{fontSize:'40px' }}>Quality Assurance
                     </span>
                </h2>
                <div>
                  <Row>
                  <Col sm="8">
                  <p style={{fontSize:'20px' }}> NDOT teams are focused on delivering high-quality software products. We follow industry best practices for software development, use the latest software development tools, and implement robust testing and quality assurance processes.
                  </p>
                  
                  <Button className="btn" color="primary">Read More</Button>
                  </Col>
                  <Col  sm="4">
                  <img
                      alt="..."
                      className="sectiononeimg img-fluid "
                      src={require("assets/img/brand/ai.png")}
                    
                    />
                  </Col>
                  
                  </Row>
                  
                </div>
               
              </Col>
            </Row>
         
            </Container>
        </section>
        <section
          className="section section-components pb-0 secheight"
          id="section-components"
          style={{backgroundColor:'#f0fff4' }}
        >
                    <Container style={{marginTop:'5%'}}>

            <Row className="justify-content-center">
              <Col lg="12"> */}
                {/* section 2*/}
                
                {/* <div>
                  <Row>
                  <Col  sm="4">
                  <img
                      alt="..."
                      className="sectiontwoimg img-fluid  mt-5"
                      src={require("assets/img/brand/ai2.png")}
                    
                    />
                  </Col>
                  <Col sm="8">
                  <h2 className=" font-weight-bold text-dark  mb-3 mt-5 " >
                  <span style={{fontSize:'40px' }}>Communication
                      </span>
                </h2>
                  <p style={{fontSize:'20px' }}> Communication is essential for the success of our team. Our team will have a clear understanding of the client's requirements, goals, and expectations. The client must also provide regular feedback and guidance to ensure that the development process stays on track.
                  </p>
                
                  <Button className="btn" color="primary">Read More</Button>
                  </Col>
                
                  
                  </Row>
                  
                </div>
               
              </Col>
            </Row>
            </Container>
            </section> */}
        
       <Carousel />
       <CustomControls />
        <CardsFooter />

      </>
    );
  }
}

export default Customers;
