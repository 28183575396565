
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {  Container, Row, Col, Button } from "reactstrap";

//this file is home page banner file

class Hero extends React.Component {
  render() {
    return (
      <>
        <div className="position-relative">
          {/* Hero for FREE version */}
          <section className="section section-hero section-shaped">
            {/* Background circles */}
            <div className="shape shape-style-1 shape-default">
              <span className="span-150" />
              <span className="span-50" />
              <span className="span-50" />
              <span className="span-75" />
              <span className="span-100" />
              <span className="span-75" />
              <span className="span-50" />
              <span className="span-100" />
              <span className="span-50" />
              <span className="span-100" />
              <img
                      alt="..."
                      className="heroimg img-fluid "
                      src={require("assets/img/brand/heroo.png")}
                    
                    />
            </div>
            <Container className="shape-container d-flex align-items-center py-lg">
              <div className="col px-0">
                <Row className="align-items-center ">
                  <Col className="text-left" lg="6">
                    {/* <img
                      alt="..."
                      className="img-fluid"
                      src={require("assets/img/brand/ndot_logo.png")}
                      style={{ width: "50%" }}
                    /> */}
                    <div className="fronttext">
                    <h1 style={{color:'#181818de',fontWeight:'600',wordSpacing:'-4px'}} className="hometext ">
                    We will walk you through the entire journey of
                    <span className="text-primary"> Digital Transformation</span>

                    </h1>
                    <Row className="buttonrow">
                      {/* <Col>
                      <Link to="/customers">
                    <Button color="primary" style={{width:'145px'}}>
                  Case Studies

                  </Button></Link>
                    </Col> */}
                    <Col>
                    <Link to=
                    'contact-us'>

                    <Button color="dark" outline type="button">
                  Contact Us

                  </Button>   
                  </Link>

                    </Col>
                    </Row>
                    </div>
                   
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            {/* <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div> */}
          </section>
        </div>
      </>
    );
  }
}

export default Hero;
