import React from "react";
import { Helmet } from 'react-helmet';

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import Bigdatabanner from "./Bigdatabanner.js";
//section 2

class Bigdata extends React.Component {
  render() {
    return (
      <>
      <Helmet>
        <title>Bigdata Analysis</title>
        <meta name="description" content="This is a description of my page." />
      </Helmet>
             <DemoNavbar />
              <Bigdatabanner /> 
        <section
          className="section section-components pb-0"
          id="section-components"
        >
           <Container style={{marginTop:'5%'}}>
            <Row className="justify-content-center">
              <Col lg="12">
                {/* section 2*/}
                <h2 className=" font-weight-bold text-dark mb-3 mt-5 " >
                  <span style={{fontSize:'40px' }}>Ingestion</span>
                </h2>
                <div>
                  <Row>
                  <Col sm="8">
                  <p style={{fontSize:'20px' }}> Collecting data from various sources and formats, including structured, semi-structured, and unstructured data. NDOT may use technologies such as Kafka, Flume, or NiFi to ingest data.

                  </p>
                
                  {/* <Button className="btn mb-5" color="primary">Read More</Button> */}
                  </Col>
                  <Col  sm="4">
                  <img
                      alt="..."
                      className="sectiononeimg img-fluid "
                      src={require("assets/img/brand/section1.png")}
                    
                    />
                  </Col>
                  
                  </Row>
                  
                </div>
               
              </Col>
            </Row>
            </Container >
            </section>
            <section
          className="section section-components pb-0 secheight"
          id="section-components"
          style={{backgroundColor:'#f0fff4' }}
        >
                    <Container style={{marginTop:'5%'}}>

            <Row className="justify-content-center">
              <Col lg="12">
                {/* section 2*/}
                
                <div>
                  <Row>
                  <Col  sm="4">
                  <img
                      alt="..."
                      className="sectiontwoimg img-fluid  mt-5"
                      src={require("assets/img/brand/ai2.png")}
                    
                    />
                  </Col>
                  <Col sm="8">
                  <h2 className=" font-weight-bold text-dark  mb-3 mt-5 " >
                  <span style={{fontSize:'40px' }}>Storage
                      </span>
                </h2>
                  <p style={{fontSize:'20px' }}>  Storing large volumes of data. NDOT may use technologies such as Hadoop Distributed File System (HDFS), NoSQL databases, or cloud-based data storage services such as Amazon S3 or Microsoft Azure Storage.
                  </p>
                  
                  {/* <Button className="btn" color="primary">Read More</Button> */}
                  </Col>
                
                  
                  </Row>
                  
                </div>
               
              </Col>
            </Row>
            </Container>
            </section>
            <section
          className="section section-components pb-0 secheight"
          id="section-components"
        >
                    <Container style={{marginTop:'5%'}}>
            <Row className="justify-content-center">
              <Col lg="12">
                {/* section 2*/}
                <h2 className=" font-weight-bold text-dark mb-3 mt-5 " >
                  <span style={{fontSize:'40px' }}>Processing
                     </span>
                </h2>
                <div>
                  <Row>
                  <Col sm="8">
                  <p style={{fontSize:'20px' }}> Processing and transforming data to make it ready for analysis. NDOT may use technologies such as Apache Spark, Apache Storm, or Apache Flink to process data in real-time or batch mode.
                  </p>
                  
                  {/* <Button className="btn" color="primary">Read More</Button> */}
                  </Col>
                  <Col  sm="4">
                  <img
                      alt="..."
                      className="sectiononeimg img-fluid "
                      src={require("assets/img/brand/ai.png")}
                    
                    />
                  </Col>
                  
                  </Row>
                  
                </div>
               
              </Col>
            </Row>
         
            </Container>
        </section>
        <section
          className="section section-components pb-0 secheight"
          id="section-components"
          style={{backgroundColor:'#f0fff4' }}
        >
                    <Container style={{marginTop:'5%'}}>

            <Row className="justify-content-center">
              <Col lg="12">
                {/* section 2*/}
                
                <div>
                  <Row>
                  <Col  sm="4">
                  <img
                      alt="..."
                      className="sectiontwoimg img-fluid  mt-5"
                      src={require("assets/img/brand/ai2.png")}
                    
                    />
                  </Col>
                  <Col sm="8">
                  <h2 className=" font-weight-bold text-dark  mb-3 mt-5 " >
                  <span style={{fontSize:'40px' }}>Analysis
                      </span>
                </h2>
                  <p style={{fontSize:'20px' }}>  Analyzing data to extract insights and value. It may use technologies such as machine learning, data mining, or natural language processing to perform complex analysis on large volumes of data.
                  </p>
                
                  {/* <Button className="btn" color="primary">Read More</Button> */}
                  </Col>
                
                  
                  </Row>
                  
                </div>
               
              </Col>
            </Row>
            </Container>
            </section>
            <section
          className="section section-components pb-0 secheight"
          id="section-components"
        >
                    <Container style={{marginTop:'5%'}}>
            <Row className="justify-content-center">
              <Col lg="12">
                {/* section 2*/}
                <h2 className=" font-weight-bold text-dark mb-3 mt-5 " >
                  <span style={{fontSize:'40px' }}>Visualization

                     </span>
                </h2>
                <div>
                  <Row>
                  <Col sm="8">
                  <p style={{fontSize:'20px' }}> Presenting the insights and results of the analysis in an intuitive and interactive way. NDOT may use technologies such as Tableau, QlikView, or D3.js to create visualizations and reports.
                  </p>
                 
                  {/* <Button className="btn" color="primary">Read More</Button> */}
                  </Col>
                  <Col  sm="4">
                  <img
                      alt="..."
                      className="sectiononeimg img-fluid "
                      src={require("assets/img/brand/ai.png")}
                    
                    />
                  </Col>
                  
                  </Row>
                  
                </div>
               
              </Col>
            </Row>
         
            </Container>
        </section>
        <section
          className="section section-components pb-0 secheight"
          id="section-components"
          style={{backgroundColor:'#f0fff4' }}
        >
                    <Container style={{marginTop:'5%'}}>

            <Row className="justify-content-center">
              <Col lg="12">
                {/* section 2*/}
                
                <div>
                  <Row>
                  <Col  sm="4">
                  <img
                      alt="..."
                      className="sectiontwoimg img-fluid  mt-5"
                      src={require("assets/img/brand/ai2.png")}
                    
                    />
                  </Col>
                  <Col sm="8">
                  <h2 className=" font-weight-bold text-dark  mb-3 mt-5 " >
                  <span style={{fontSize:'40px' }}>Management and governance
                      </span>
                </h2>
                  <p style={{fontSize:'20px' }}>  Managing and governing the entire data analytics process. NDOT may use technologies such as Apache Ranger, Apache Atlas, or Apache Zeppelin to ensure data security, compliance, and collaboration.
                  </p>
                
                  {/* <Button className="btn" color="primary">Read More</Button> */}
                  </Col>
                
                  
                  </Row>
                  
                </div>
               
              </Col>
            </Row>
            </Container>
            </section>
        <CardsFooter />

      </>
    );
  }
}

export default Bigdata;
