
import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  // NavLink,
  Nav,
  Container,
  Row,
  Col,
  // UncontrolledTooltip
} from "reactstrap";

class DemoNavbar extends React.Component {
 

 
  
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out"
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: ""
    });
  };

  render() {
    return (
      <>
        <header className="header-global">
          <Navbar 
            className="navbar-main navbar-transparent navbar-light headroom backwhite"
            expand="lg"
            id="navbar-main"
          >
            <Container >
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img
                  alt="..."
                  src={require("assets/img/brand/ndot1(1).png")}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("assets/img/brand/ndot1(1).png")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="navbar-nav-hover align-items-lg-center " navbar>
                  <UncontrolledDropdown nav className="hover-underline-animation">
                    <DropdownToggle nav >
                      {/* <i className="fa fa-lightbulb-o d-lg-none mr-1 " /> */}
                      <span className="nav-link-inner--text " >Solutions</span>
                    </DropdownToggle>
              <DropdownMenu className="dropdown-menu-xl">
                      <Row style={{marginLeft:"10%"}}>
                      <div className="dropdown-menu-inner">
                      <Col sm='3'>
                        <Media
                          className="d-flex align-items-center"
                         
                        >
                         
                        
                          <Media body className="ml-3">
                          <Link to="/application-engineering">
                            <h5 className=" text-dark mb-md-1">
                            Application Engineering

                            </h5>
                            <p className="description d-none d-md-inline-block mb-0" style={{color:'rgb(0 0 0)'}}>
                            Modernizing an application provide a better user experience for their customers and employees.
                            </p>
                            </Link>
                          </Media>
                         
                        </Media>
                        </Col>
                        <Col  sm='3'>
                        <Media
                          className="d-flex align-items-center"
                        
                        >
                         
                          <Media body className="ml-3">
                          <Link to="/bigdata-analytics">

                            <h5 className=" text-dark mb-md-1">
                            Big data analytics

                            </h5>
                            <p className="description d-none d-md-inline-block mb-0" style={{color:'rgb(0 0 0)'}}>
                            This can help organizations make informed decisions, identify trends, and optimize business processes.
                            </p>
                            </Link>
                          </Media>
                         
                        </Media>
                        </Col>
                        <Col  sm='3'>
                        <Media
                          className="d-flex align-items-center"
                          
                        >
                          
                       
                          <Media body className="ml-3">
                            <Link to="/coversational-ai">
                            <h5 className=" text-dark mb-md-1">
                            Conversational AI

                            </h5>
                            <p className="description d-none d-md-inline-block mb-0" style={{color:'rgb(0 0 0)'}}>
                            the development of computer systems that can perform tasks that typically require human intelligence.
                            </p>
                            </Link>
                          </Media>
                         
                        </Media>
                        </Col>
                      </div>
                      </Row>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                
                  <UncontrolledDropdown nav className="hover-underline-animation">
                    <DropdownToggle nav>
                      {/* <i className="ni ni-ui-04 d-lg-none mr-1" /> */}
                      <span className="nav-link-inner--text">Services</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl2">
                      <div className="dropdown-menu-inner">
                        <Row style={{marginLeft:"6%"}}>
                          <Col lg="4">
                          <Media body className="ml-3">
                            <h5 className=" text-dark mb-4">
                            Build
                            </h5>
                            
                          </Media>
                        <Media
                          className="d-flex align-items-center"
                    
                        >
                          
                          <Media body className="ml-3">
                            <Link to="/product-development-team">
                            <h5 className=" text-dark mb-md-1">
                            Product Development Team
                            </h5>
                            <p className="description d-none d-md-inline-block mb-0" style={{color:'rgb(0 0 0)'}}>
                            We can build you an Outsourced Product development team (OPD) based on your expectations, technology and timeline requirements. 
                            </p>
                            </Link>
                          </Media>
                        </Media>
                        <Media
                          className="d-flex align-items-center"
                         
                        >
                        
                          <Media body className="ml-3">
                            <Link to="/offshore-development-center">
                            <h5 className=" text-dark mb-md-1">
                            Offshore development Center (ODC)

                            </h5>
                            <p className="description d-none d-md-inline-block mb-0" style={{color:'rgb(0 0 0)'}}>
                            We help you to establish a dedicated development team in India to complete a specific project or handle ongoing software development needs.
                            </p>
                            </Link>
                          </Media>
                        </Media>
                        </Col>
                        <Col lg="4">
                        <Media body className="ml-3">
                            <h5 className=" text-dark mb-4">
                          Hire
                            </h5>
                            
                          </Media>
                        <Media
                          className="d-flex align-items-center"
                       
                        >
                        
                          <Media body className="ml-3">
                            <Link to="/backend-developer">
                            <h5 className=" text-dark mb-md-1">
                            Backend Developers

                            </h5>
                            <p className="description d-none d-md-inline-block mb-0" style={{color:'rgb(0 0 0)'}}>
                            
                            Our team of Backend developers are capable of handling your application requirements on various platforms                             and processes. 

                            </p>

                            </Link>
                          </Media>
                        </Media>
                        <Media
                          className="d-flex align-items-center"
                        
                        >
                        
                          <Media body className="ml-3">
                            <Link to="/front-developer">
                            <h5 className=" text-dark mb-md-1">
                            Frontend developers

                            </h5>
                            <p className="description d-none d-md-inline-block mb-0" style={{color:'rgb(0 0 0)'}}>
                           
                            Our team of Javascript developers are capable of handling your user experience requirements on various frameworks. 

                            </p>
                            </Link>
                
                          </Media>
                        </Media>
                        <Media
                          className="d-flex align-items-center"
                        
                        >
                         
                          <Media body className="ml-3">
                            <Link to="/mobile-application">
                            <h5 className=" text-dark mb-md-1">
                            Mobile application


                            </h5>
                            <p className="description d-none d-md-inline-block mb-0" style={{color:'rgb(0 0 0)'}}>
                           
                            Our team of Mobile application developers are capable of handling your mobile experience requirements on various frameworks. 

                            </p>
                            </Link>
                          </Media>
                        </Media>
                        </Col>
                     </Row>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav className="hover-underline-animation">
                    <DropdownToggle nav>
                      {/* <i className="ni ni-ui-04 d-lg-none mr-1" /> */}
                      <span className="nav-link-inner--text">Products</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl3">
                      <div className="dropdown-menu-inner">
                        <Row style={{marginLeft:"9%"}}>
                        <Media
                          className="d-flex align-items-center"
                          href="https://www.movex.ai/"
                          target="_blank"
                        >
                        <Col lg="7">
                          <Media body className="ml-3">
                            <h5 className=" text-dark mb-md-1">
                             Movex
                            </h5>
                            <p className="description d-none d-md-inline-block mb-0" style={{color:'rgb(0 0 0)'}}>
                            Redefining the way your business operates by adopting savvy on-demand solutions at affordable cost. Movex, a perfect choice for your mobility venture!
                            </p>
                          </Media>
                          </Col>
                        </Media>
                        </Row>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav className="hover-underline-animation">
                    <DropdownToggle nav>
                      {/* <i className="ni ni-ui-04 d-lg-none mr-1" /> */}
                      <span className="nav-link-inner--text">Customers</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl4">
                      <div className="dropdown-menu-inner">
                      <Row style={{marginLeft:"9%"}}>
                        <Media
                          className="d-flex align-items-center"
                          
                        >
                          <Col >
                          <Media body className="ml-3">
                          <Link to="/customers">
                            <h5 className=" text-dark mb-md-1">
                              Our Valuable Customers
                            </h5>
                            <p className="description d-none d-md-inline-block mb-0" style={{color:'rgb(0 0 0)'}}>
                            Trusted by businesses across the globe.

                            </p>
                            </Link>
                          </Media>
                          </Col>
                        </Media>
                        </Row>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav className="hover-underline-animation">
                    <DropdownToggle nav>
                      {/* <i className="ni ni-collection d-lg-none mr-1" /> */}
                      <span className="nav-link-inner--text">Company</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl5">
                      <div className="dropdown-menu-inner">
                        <Row style={{marginLeft:"7%"}}>
                          <Col lg="3">
                        <Media
                          className="d-flex align-items-center"
                         
                        >
                         
                          <Media body className="ml-3">
                          <Link to="/vision">
                            <h5 className=" text-dark mb-md-1">
                             Vision
                            </h5>
                            <p className="description d-none d-md-inline-block mb-0" style={{color:'rgb(0 0 0)'}}>
                            Be the leader in technology by providing Intelligence to make smarter decisions.
                            </p>
                            </Link>
                          </Media>
                        </Media>
                        </Col>
                        <Col lg="3">
                        <Media
                          className="d-flex align-items-center"
                     
                        >
                          
                          <Media body className="ml-3">
                          <Link to="/leadership">
                            <h5 className=" text-dark mb-md-1">
                             Leadership Team
                            </h5>
                            <p className="description d-none d-md-inline-block mb-0" style={{color:'rgb(0 0 0)'}}>
                            Work together to drive the organization's success.
                            </p>
                            </Link>
                          </Media>
                        </Media>
                        </Col>
                        <Col lg="3">
                        <Media
                          className="d-flex align-items-center"
                         
                        >
                          
                          <Media body className="ml-3">
                            <Link to="/about-us">
                            <h5 className=" text-dark mb-md-1">
                              About Us
                            </h5>
                            <p className="description d-none d-md-inline-block mb-0" style={{color:'rgb(0 0 0)'}}>
                            We are a leading provider of innovative IT solutions for businesses of all sizes. 
                            </p>
                            </Link>
                          </Media>
                        </Media>
                        </Col>
                        </Row>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  {/* <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.facebook.com/creativetim"
                      id="tooltip333589074"
                      target="_blank"
                    >
                      <i className="fa fa-facebook-square" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Facebook
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip333589074">
                      Like us on Facebook
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.instagram.com/creativetimofficial"
                      id="tooltip356693867"
                      target="_blank"
                    >
                      <i className="fa fa-instagram" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Instagram
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip356693867">
                      Follow us on Instagram
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://twitter.com/creativetim"
                      id="tooltip184698705"
                      target="_blank"
                    >
                      <i className="fa fa-twitter-square" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Twitter
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip184698705">
                      Follow us on Twitter
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://github.com/creativetimofficial/argon-design-system-react"
                      id="tooltip112445449"
                      target="_blank"
                    >
                      <i className="fa fa-github" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Github
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip112445449">
                      Star us on Github
                    </UncontrolledTooltip>
                  </NavItem> */}
                  <NavItem className="d-none d-lg-block ml-lg-4">
                    
                     
                       
                     <Link to="/contact-us" className="text-dark">
                      <span className="nav-link-inner--text ml-1">
                      Contact Us
                      </span>
                      </Link>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default DemoNavbar;
