import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//section 2

class BasicElements extends React.Component {
  render() {
    return (
      <>
        <section
          className="section section-components pb-0 secheight"
          id="section-components"
         
        >
          <Container style={{marginTop:'5%'}}>
            <Row className="justify-content-center">
              <Col lg="12">
                {/* section 2*/}
                <h2 className=" font-weight-bold text-dark mb-3 mt-5 " >
                  <span style={{fontSize:'40px' }}>Application Engineering</span>
                </h2>
                <div>
                  <Row>
                  <Col sm="8">
                  <p style={{fontSize:'20px' }}>  Application Engineering Solutions is the process of designing, developing, testing, deploying and maintaining software applications for businesses and organizations. These solutions can help in improving productivity, enhancing customer experience, and increasing profitability. 
                  </p>
                  <Link to="/application-engineering"><Button className="btn mb-5" color="primary">Read More</Button></Link>
                  </Col>
                  <Col  sm="4">
                  <img
                      alt="..."
                      className="sectiononeimg img-fluid "
                      src={require("assets/img/brand/section1.png")}
                    
                    />
                  </Col>
                  
                  </Row>
                  
                </div>
               
              </Col>
            </Row>
            </Container >
            </section>
            <section
          className="section section-components pb-0 secheight"
          id="section-components"
          style={{backgroundColor:'#f0fff4' }}
        >
                    <Container style={{marginTop:'5%'}}>

            <Row className="justify-content-center">
              <Col lg="12">
                {/* section 2*/}
                
                <div>
                  <Row>
                  <Col  sm="4">
                  <img
                      alt="..."
                      className="sectiontwoimg img-fluid  mt-5"
                      src={require("assets/img/brand/ai2.png")}
                    
                    />
                  </Col>
                  <Col sm="8">
                  <h2 className=" font-weight-bold text-dark  mb-3 mt-5 " >
                  <span style={{fontSize:'40px' }}>Big data analytics</span>
                </h2>
                  <p style={{fontSize:'20px' }}>  Big Data and ETL are essential components of modern data processing and analytics. Big Data technologies provide the infrastructure and tools to process and store large volumes of data, while ETL is used to integrate and process the data for analysis and decision-making.
                  </p>
                  <Link to="/bigdata-analytics"><Button className="btn mb-5" color="primary">Read More</Button></Link>
                  </Col>
                
                  
                  </Row>
                  
                </div>
               
              </Col>
            </Row>
            </Container>
            </section>
            <section
          className="section section-components pb-0 secheight"
          id="section-components"
        >
                    <Container style={{marginTop:'5%'}}>
            <Row className="justify-content-center">
              <Col lg="12">
                {/* section 2*/}
                <h2 className=" font-weight-bold text-dark mb-3 mt-5 " >
                  <span style={{fontSize:'40px' }}>Conversational AI</span>
                </h2>
                <div>
                  <Row>
                  <Col sm="8">
                  <p style={{fontSize:'20px' }}>  Conversation AI, also known as chatbots or virtual assistants, is a rapidly growing technology that uses natural language processing (NLP) and machine learning (ML) to interact with users in a conversational way.
                  </p>
                  <Link to="/coversational-ai"><Button className="btn mb-5" color="primary">Read More</Button></Link>
                  </Col>
                  <Col  sm="4">
                  <img
                      alt="..."
                      className="sectiononeimg img-fluid "
                      src={require("assets/img/brand/ai.png")}
                    
                    />
                  </Col>
                  
                  </Row>
                  
                </div>
               
              </Col>
            </Row>
         
            </Container>
            </section>
      </>
    );
  }
}

export default BasicElements;
