import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

//section 2

class Leadership extends React.Component {
  render() {
    return (
      <>
      <Helmet>
        <title>Leadership</title>
        <meta name="description" content="This is a description of my page." />
      </Helmet>
             <DemoNavbar />
             <div className="position-relative">
          <section className="section section-hero section-shaped">
            {/* Background circles */}
            <div className="shape shape-style-1 shape-default">
          
              <img
                      alt="..."
                      className="heroimg img-fluid "
                      src={("https://www.inspireone.in/wp-content/uploads/2021/10/leadership.png")}
                    
                    />
            </div>
            <Container className="shape-container d-flex align-items-center py-lg">
              <div className="col px-0">
                <Row className="align-items-center ">
                  <Col className="text-left" lg="6">
                    {/* <img
                      alt="..."
                      className="img-fluid"
                      src={require("assets/img/brand/ndot_logo.png")}
                      style={{ width: "50%" }}
                    /> */}
                    <div className="fronttext mt-5">
                    <h1 style={{color:'#181818de',fontWeight:'700',fontSize:'35px' }} className="hometext ">
                    Good leadership can make all the difference in the success of a team or organization.


                    <span className="text-success">
                     </span>

                    </h1>
                    <Row className="buttonrow">
                      {/* <Col>
                      <Link>
                    <Button color="primary" style={{width:'145px'}}>
                  Case Studies

                  </Button></Link>
                    </Col> */}
                    <Col>
                    <Link to=
                    'contact-us'>

                    <Button color="dark" outline type="button">
                  Contact Us

                  </Button>   
                  </Link>

                    </Col>
                    </Row>
                    </div>
                   
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            {/* <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div> */}
          </section>
        </div>
        <section
          className="section section-components pb-0"
          id="section-components"
        >
           <Container >
            <Row className="justify-content-center">
              <Col lg="12">
                {/* section 2*/}
                <h2 className=" font-weight-bold text-dark mb-3 mt-5 " style={{display:'flex',justifyContent:'center'}}>
                  <span style={{fontSize:'40px' }}>How it all started</span>
                </h2>
                <h5 className=" font-weight-bold text-dark mb-3" style={{display:'flex',justifyContent:'center'}}>
                  "The only way to do great work is to love what you do".
                </h5>
                <div>
                  <Row>
                  <Col sm="12" >
                  <p style={{fontSize:'20px' ,display:'flex',justifyContent:'center',textAlign:'center'}}>
                  At NDOT, we believe that passion and dedication are essential ingredients for achieving success. We agree with Steve Jobs' quote, "The only way to do great work is to love what you do," and we strive to create an environment where our employees can find work that they are passionate about.

                  </p>
                
                  
                  </Col>
                  {/* <Col  sm="4">
                  <img
                      alt="..."
                      className="sectiononeimg img-fluid "
                      src={require("assets/img/brand/section1.png")}
                    
                    />
                  </Col>
                   */}
                  </Row>
                  
                </div>
               
              </Col>
            </Row>
            </Container >
            </section>
            <section
          className="section section-components pb-0 secheight"
          id="section-components"
        
        >
                    <Container>

            <Row className="justify-content-center">
              <Col lg="12">
                {/* section 2*/}
                
                <div>
                  <Row>
                  <Col  sm="4">
                  <img
                      alt="..."
                      className="sectiontwoimg img-fluid  mt-5"
                      src={require("assets/img/brand/Nanda.png")}
                    width={"100%"}
                    />
                  </Col>
                  <Col sm="8">
                  <h2 className=" font-weight-bold text-dark  mb-1 mt-1 " >
                  <span style={{fontSize:'35px' }}>CEO Note : Nandakumar

                      </span>
                </h2>
                  <h5 style={{fontSize:'25px' }}>  We really welcome our customers, vendors & team to build a strong ecosystem of digital transformation to empower end customers and people.

                  </h5>
                  <p style={{fontSize:'18px' }}>Our CEO, Nandakumar has more than 15+ years of industry experience varies from Healthcare, Logistics, Transportation & Banking. 
                He has been awarded as fastest growing SaaS company in 2018 by Entrepreneur magazine.
                He has multiple startup experience from IT to automotive to transportation.
                </p>
                <p style={{fontSize:'18px' }} >
                 Nandakumar is recognized for his leadership skills, innovation, and commitment to excellence. He has received several awards and accolades for his contributions to the technology industry, including the Best CEO award from the International Business Awards. With his extensive experience and expertise, Nandakumar continues to lead Ndot Technologies to new heights of success and innovation in the software development industry.

                </p>
                <blockquote className="blockquote text-center">
 
  <footer className="blockquote-footer">
  "The people who are crazy enough to think they can change the world are the ones who do."

  </footer>
</blockquote>
                <br></br>
                
                  </Col>
                
                  
                  </Row>
                  
                </div>
               
              </Col>
            </Row>
            </Container>
            </section>
      
         
       
        <CardsFooter />

      </>
    );
  }
}

export default Leadership;
