
import React, { Component } from 'react';
import emailjs from "@emailjs/browser";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Label,
  UncontrolledAlert 
} from "reactstrap";
import { Link } from 'react-router-dom';

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Register extends React.Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();
  }

  
  sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ylfipct', 'template_t0e507f', this.form.current, 'akRmbV5hirk5BpM6Y')
  
   
      .then((result) => {
          console.log(result.text);
          toast('Email send Successfully', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            
            });
      }, (error) => {
          console.log(error.text);
      });

        // Reset the form after submitting
    this.form.current.reset();
  };

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }


  render() {
    return (
      <>

<ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
        <DemoNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 " style={{backgroundColor:'' }}>
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="pt-lg-7 contactsize">
           
              <Row className="justify-content-center " >
              
                <Col sm="7">
                <span class="material-symbols-outlined googleicn" >
                  apartment
                  </span><h1>WORKING TOGETHER</h1>
              <p>We’ve driven growth and purposeful transformation across every industry and we’re excited to build on your belief. Tell us a bit more about yourself, so we can get the ball rolling.

              </p>
              </Col>
              </Row>
              <Row  className="justify-content-center">
                <Col md="6">
              <Form innerRef={this.form} onSubmit={this.sendEmail} className="formleft">
          <Row>
          <Col md="12" >
              <FormGroup>
              <Label for="nameInput">Name</Label>
            <Input type="text" name="from_name" id="nameInput" placeholder="Enter your name" required/>           
           </FormGroup>
            </Col>
           
          </Row>
          <Row>
          <Col md="12" >
              <FormGroup>
              <Label for="cnameInput">Company Name</Label>
<Input type="text" name="from_companyname" id="cnameInput" placeholder="Enter your company name" required/>           
   </FormGroup>
            </Col>
           
          </Row>
          <Row>
          <Col md="6">
              <FormGroup>
              <Label for="emailInput">Email</Label>
               <Input type="email" name="from_email" id="emailInput" placeholder="Enter your email address" required/>
              </FormGroup>
            </Col>
            <Col md="6">
            <FormGroup>
             <Label for="phoneInput">Phone Number</Label>
          <Input type="tel" name="phone_number" placeholder="Enter your phone number" required/>
              </FormGroup>
            </Col>
          </Row>
          <Row>
          <Col md="12">
            <FormGroup>
            <Label for="industry">Industry</Label>

    <Input
     placeholder="Industry"
    className="mb-3"
    type="select"
    id="industry"
    name="from_industry"
    required
  >
      <option>
       Banking
      </option>
      <option>
       Capital Markets
      </option>
      <option>
       Insurance
      </option>
      <option>
        Healthcare
      </option>
      <option>
       Life Science
      </option>
    </Input>
  </FormGroup>
            </Col>
          </Row>
          <Row>
           
            <Col md="12">
              <FormGroup >
              <Label for="messageInput">Message</Label>

              <Input type="textarea" name="message" id="messageInput" placeholder="Enter your message" required />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="10">
        <Button color="primary" type="submit"   className="justify-content-center">
         Send
        </Button>
        </Col>
          </Row>
        </Form>
        </Col>
                   <Col className='addressleft' sm="4">
                    <div>
                  <h3 >USA | MOVEX Inc.</h3>
                  <p>
                    <span style={{display:'block'}}>1337, Cow Ct,</span>
                    {/* <span  style={{display:'block'}}>#409 San Ramon,</span> */}
                    <span  style={{display:'block'}}> Tracy 95391, United States.</span>
                    {/* <span  style={{display:'block'}}>Email: contactus@ndot.in</span>  */}
                    </p>
                    </div>

                    
                    <div className='mt-5'>

                    <h3 >INDIA | NDOT Technologies Pvt Ltd.</h3>
                  <p>
                    <span style={{display:'block'}}>No 2, Phase II, Balaji Nagar,</span>
                    {/* <span  style={{display:'block'}}>#409 San Ramon,</span> */}
                    <span  style={{display:'block'}}> Coimbatore, India.</span>
                    <span  style={{display:'block'}}>Phone: +91-4223138970</span> 
                    {/* <span  style={{display:'block'}}>Email: contactus@ndot.in</span>  */}
                    </p>
                    </div>
                  </Col>

                  
              </Row>
              
            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Register;
