
import React from "react";
import { Link } from "react-router-dom";
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

// reactstrap components
import {
  Button,
  Card,
  CardImg,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  NavbarBrand
} from "reactstrap";

class CardsFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="footer has-cards">
          <section></section>
          <Container >
   
      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5 '  >
          <MDBRow className='mt-3' style={{textAlign:'left'  }}>
            <MDBCol md="3"  className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img
                  alt="..."
                  src={require("assets/img/brand/ndot1(1).png")}
                />
              </NavbarBrand>
               
              </h6>
              <p ><strong>
              NDOT delivers sustainable solutions using AI, IoT and Big Data to position enterprises as distinctive front-runners.
              </strong> </p>
            </MDBCol>

            <MDBCol md="2"  className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Solutions</h6>
              <p>
                <Link to='/application-engineering' className='text-reset'>
                Application Engineering

                </Link>
              </p>
              <p>
                <Link to='/bigdata-analytics'  className='text-reset'>
                Big Data analytics
                </Link>
              </p>
              <p>
                <Link to='/coversational-ai'  className='text-reset'>
                Conversational AI

                </Link>
              </p>
             
            </MDBCol>
            <MDBCol md="3"  className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Services</h6>
              <p>
              <Link to='/product-development-team'  className='text-reset'>
                Product Development Team
                </Link>
              </p>
              <p>
                <Link to='/offshore-development-center' className='text-reset'>
                Offshore development Center
                </Link>
              </p>
              <p>
              <Link to='/backend-developer'  className='text-reset'>
                Backend Developers
               </Link>
              </p>
              <p>
              <Link to='/front-developer'  className='text-reset'>
                Frontend developers
             </Link>
              </p>
              <p>
              <Link to='/mobile-application'  className='text-reset'>
                Mobile application


               </Link>
              </p>
            
            </MDBCol>
            <MDBCol md="2"  className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Customers</h6>
              <p>
              <Link to='/customers'  className='text-reset'>
                Our Valuable Customers
               </Link>
              </p>
             
            </MDBCol>

            <MDBCol md="2"  className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>COMPANY</h6>

              <Link to='/vision'  className='text-reset'>
              <p>

            Vision
              </p>
              </Link>
              <Link to='/leadership'  className='text-reset'>
              <p>
                
                Leadership Team
              </p>
              </Link>
              <Link to='/about-us'  className='text-reset'>
              <p>
                About Us
              </p>
              </Link>
            </MDBCol>
          
          </MDBRow>
        </MDBContainer>
      </section>
            <hr />
            <Row className="align-items-center justify-content-md-between">
              <Col md="6">
                <div className="copyright">
                  © {new Date().getFullYear()}{" "}
                 
                    NDOT
                  
                  . All Rights Reserved
                </div>
              </Col>
              <Col md="6 justify-content-md-end" style={{display:'flex'}}>
             
     <Button className="btn-icon-only rounded-circle" color="#f4f5f7" href="https://twitter.com/ndottechnology" id="tooltip475038074" target="_blank" > 
     <span className="btn-inner--icon"> <i className="fa fa-twitter" /> </span> 
     </Button> 
     <UncontrolledTooltip delay={0} target="tooltip475038074"> Follow us </UncontrolledTooltip>
      <Button className="btn-icon-only rounded-circle ml-1" color="#f4f5f7" href="https://www.facebook.com/ndotin" id="tooltip837440414" target="_blank" >
         <span className="btn-inner--icon"> <i className="fa fa-facebook-square" /> </span>
       </Button>
       
       <Button className="btn-icon-only rounded-circle ml-1" color="#f4f5f7" href="https://www.linkedin.com/company/ndot-technologies" id="tooltip495507257" target="_blank" > 
       <span className="btn-inner--icon"> <i className="fa fa-linkedin" /> </span> 
       </Button> 
       <UncontrolledTooltip delay={0} target="tooltip495507257"> Comment on Linked-in </UncontrolledTooltip>
      
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default CardsFooter;
