import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

// reactstrap components
import { Button, Container, Row, Col,PopoverBody, UncontrolledPopover  } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

//section 2

class Aboutus extends React.Component {
  render() {
    return (
      <>
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="This is a description of my page." />
      </Helmet>
             <DemoNavbar />
             <div className="position-relative">
          <section className="section section-hero section-shaped">
            {/* Background circles */}
            <div className="shape shape-style-1 shape-default">
          
              <img
                      alt="..."
                      className="heroimg img-fluid "
                      src={require("assets/img/brand/heroo.png")}
                    
                    />
            </div>
            <Container className="shape-container d-flex align-items-center py-lg">
              <div className="col px-0">
                <Row className="align-items-center ">
                  <Col className="text-left" lg="6">
                    {/* <img
                      alt="..."
                      className="img-fluid"
                      src={require("assets/img/brand/ndot_logo.png")}
                      style={{ width: "50%" }}
                    /> */}
                    <div className="fronttext">
                    <h1 style={{color:'#181818de',fontWeight:'700',fontSize:'35px' }} className="hometext mt-5">
                    NDOT is a product-based company that develops software to disrupt the Transportation and Logistics sector.

                    <span className="text-success">
                     </span>

                    </h1>
                    <Row className="buttonrow">
                      {/* <Col>
                      <Link>
                    <Button color="primary" style={{width:'145px'}}>
                  Case Studies

                  </Button></Link>
                    </Col> */}
                    <Col>
                    <Link to=
                    'contact-us'>

                    <Button color="dark" outline type="button">
                  Contact Us

                  </Button>   
                  </Link>

                    </Col>
                    </Row>
                    </div>
                   
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            {/* <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div> */}
          </section>
        </div>
        <section
          className="section section-components pb-0"
          id="section-components"
        >
           <Container style={{marginTop:'5%'}}>
            <Row className="justify-content-center" >
              <Col lg="10">
                {/* section 2*/}
                <div style={{display:'flex',justifyContent:'center' }}>
                {/* <img  
                          alt="..."
                          src={("https://www.ndottech.com/public/frontend/images/timeline_logo.png")}
                        /> */}
                        </div>
                <h2 className=" font-weight-bold text-dark mb-3 mt-5 "  style={{display:'flex',justifyContent:'center' }}>
                  <span style={{fontSize:'40px',marginTop:'-40px' }}>About us</span>
                </h2>
                <div>
                  <Row>
                  <Col  lg="11">
                  <p style={{fontSize:'18px',}}>
                    <span  style={{display:'flex',justifyContent:'center',textAlign:'center',marginLeft:'5%' }}>
                    Founded in 2008, NDOT was earlier a web development company. Backed by a strong team of techies, we have emerged in the technology field with flying colours. Ours is a matrixed, enterprise-level firm pursuing the mission to uphold businesses in a technologically advanced economy. We originally entered the technology business with the aspiration to excel as a service-based company, but analysing the fleet market and its requirements, we later transformed our business processes and started developing our own products. Being the niche player in the market, we focus on sectors such as logistics and transportation to equip the industry players with efficient taxi and fleet management systems.
                    </span>
                  </p>
                
                 
                  </Col>
                  <Col  sm="4">
                  {/* <img
                      alt="..."
                      className="sectiononeimg img-fluid "
                      src={require("assets/img/brand/section1.png")}
                    
                    /> */}
                  </Col>
                  
                  </Row>
                  
                </div>
               
              </Col>
            </Row>
            </Container >
            </section>
            <section
          className="section section-components pb-0 secheight2"
          id="section-components"
          style={{backgroundColor:'rgb(227 227 227 / 83%)', }}
        >
                    <Container>

            <Row className="justify-content-center">
              <Col lg="12">
                {/* section 2*/}
                
                <div>
                <Row className="justify-content-center">
              <Col lg="6">
                <br></br>
                <br></br>

                <h1 className=" font-weight-bold  mb-3 " style={{display:'flex',justifyContent:'center' }}>
                  <span style={{color:'black' }}>The Ndots Promise</span>
                </h1>
                <h4 className="  mb-5" style={{display:'flex',justifyContent:'center',textAlign:'center' }}>
                  <span style={{color:'black' }}>There are hundreds of vendors you deal with, who may promise you a thousand things.
              Wondering how we’re different? </span>
                </h4>
                <div style={{display:'flex',justifyContent:'center' }}>
                <Button color="primary" id="tooltip165342254" type="button">
                  Read more
                 </Button>
                 <UncontrolledPopover
                   placement="top"
                   target="tooltip165342254"
                   className="popover-primary"
                 >
                   <PopoverBody >
                   We go the extra mile to meet your needs and proactively work with you to achieve the results you want. Your success is our success. 
                   </PopoverBody>
                 </UncontrolledPopover>
                         </div>
               
                
              </Col>
            </Row>
                  
                </div>
               
              </Col>
            </Row>
            </Container>
            </section>
           
     
       
        <CardsFooter />

      </>
    );
  }
}

export default Aboutus;
