import React from "react";

// reactstrap components
import { Col,Row,Container,Card,CardTitle,CardText,Button } from "reactstrap";
import { Link } from "react-router-dom";
  //section 3

class CustomControls extends React.Component {
  render() {
    return (
      <>
         <section
          className="section section-components pb-0"
          id="section-components"
          style={{backgroundColor:'aliceblue' }}
        >
          <Container >
            <Row className="justify-content-center">
              <Col lg="12">
                <br></br>
                <br></br>

                <h4 className=" font-weight-bold  mb-3" style={{display:'flex',justifyContent:'center' }}>
                  <span>Are you interested?</span>
                </h4>
                <h1 className=" font-weight-bold  mb-5" style={{display:'flex',justifyContent:'center' }}>
                  <span>Get your customized application now</span>
                </h1>
                <div style={{display:'flex',justifyContent:'center' }}>
               <Link to="/contact-us"><Button className="btn " color="primary">Get in Touch</Button></Link> 
                </div>
                <br></br>
                <br></br>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}


export default CustomControls;
