import React from "react";
import { Helmet } from 'react-helmet';

// reactstrap components
import { Col,Row,Container,Card,CardTitle,CardText,Button } from "reactstrap";
import { Link } from "react-router-dom";

  //section 1

class Alerts extends React.Component {
  render() {
    return (
      <>
      <Helmet>
        <title>Enterprise Mobility Management And Services For Business | NDOT</title>
        <meta name="description" content="This is a description of my page." />
      </Helmet>
         <section
          className="section section-components pb-0"
          id="section-components"
          style={{backgroundColor:'rgb(242 242 242 / 67%)',height:'100%'}}
        >
          <Container>
            <Row className="justify-content-center">
              <Col lg="12">
                {/* section 1*/}
                <h2 className="text-dark font-weight-bold  mb-3 " style={{display:'flex',justifyContent:'center' }}>
                  <span>Our Recent Clients</span>
                </h2>
                <p className="h4 text-primary  mb-5" style={{display:'flex',justifyContent:'center' }}>
                Our valuable customers across the world
                </p>
              
                <div>
                <Row>
                 <Col sm="3">
                   <Card body className="card-lift--hover" style={{backgroundColor:" white",height:'96%'}}>
                     <CardTitle tag="h5" className="d-flex justify-content-center">
                     <img
                      alt="..."
                      className="kotak"
                      src={require("assets/img/brand/km.png")}
                      width={"200px"}

                    />
                     </CardTitle>
                     <CardTitle tag="h5" className="d-flex justify-content-center">
                     Kotak mahindra  
                     </CardTitle>
                     <CardText style={{textAlign:"center" }}>
                     Kotak Mahindra Bank is an Indian banking and financial services company.
                     </CardText>
                     <Link><CardText style={{textAlign:"center" }}>
                     <div class='containers'>
          
                     <Link to="/customers" class='animated-arrow' >
          <p>Read more</p>
            <span class='the-arrow -left'>  

              <span class='shaft'></span>
            </span>
            <span class='main'>
              <span class='text'>
              <span>&#8203;</span>
              </span>
              <span class='the-arrow -right'>
                <span class='shaft'></span>
              </span>
            </span>
          </Link>

           </div>
                      </CardText></Link> 

                   </Card>
                 </Col>
                 <Col sm="3">
                 <Card body className="card-lift--hover" style={{backgroundColor:"white",height:'96%'}}>
                     <CardTitle tag="h5" className="d-flex justify-content-center">
                     <img
                      alt="..."
                      className="Shell"
                      src={require("assets/img/brand/shell.png")}
                    width={"120px"}
                    />
                     </CardTitle>
                     <CardTitle tag="h5" className="d-flex justify-content-center">
                     Shell
                     </CardTitle>
                     <CardText style={{textAlign:"center" }}>
                     Shell is one of the international oil company in India's energy sector.                   </CardText>
                    <Link><CardText style={{textAlign:"center" }}>
                    <div class='containers'>
          
                    <Link to="/customers" class='animated-arrow' >
          <p>Read more</p>
            <span class='the-arrow -left'>  

              <span class='shaft'></span>
            </span>
            <span class='main'>
              <span class='text'>
              <span>&#8203;</span>
              </span>
              <span class='the-arrow -right'>
                <span class='shaft'></span>
              </span>
            </span>
          </Link>

           </div>
                      </CardText></Link> 
                   </Card>
                 </Col>
                 <Col sm="3" >
                 <Card body className="card-lift--hover " style={{backgroundColor:" white",height:'96%'}}>
                     <CardTitle tag="h5" className="d-flex justify-content-center">
                     <img
                      alt="..."
                      className="Shell"
                      src={require("assets/img/brand/seac.png")}
                      width={"130px"}
                    />
                     </CardTitle>
                     <CardTitle tag="h5" className="d-flex justify-content-center mt-4">
                   SEAC
                     </CardTitle>
                     <CardText style={{textAlign:"center" }}>
                     SEAC is an Italian financial company that offers products for banking, insurance, and more.            
                          </CardText>
                          <Link><CardText style={{textAlign:"center" }}>
                                  <div class='containers'>
          
                                  <Link to="/customers" class='animated-arrow' >
          <p>Read more</p>
            <span class='the-arrow -left'>  

              <span class='shaft'></span>
            </span>
            <span class='main'>
              <span class='text'>
              <span>&#8203;</span>
              </span>
              <span class='the-arrow -right'>
                <span class='shaft'></span>
              </span>
            </span>
          </Link>

             </div>
             </CardText></Link> 

                   </Card>
                 </Col>
                 <Col sm="3">
                 <Card body className="card-lift--hover" style={{backgroundColor:"white",height:'96%',}}>
                     <CardTitle tag="h5" className="d-flex justify-content-center">
                     <img
                      alt="..."
                      className=""
                      src={require("assets/img/brand/jll.png")}
                    width={"120px"}
                    />
                     </CardTitle>
                     <CardTitle tag="h5" className="d-flex justify-content-center" > 
                     JLL
                     </CardTitle>
                     <CardText style={{textAlign:"center" }}>
                     JLL Incorporated is a global commercial real estate services company
                    </CardText>
                  <Link><CardText style={{textAlign:"center" }}> <div class='containers'>
          
          <Link to="/customers" class='animated-arrow' >
          <p>Read more</p>
            <span class='the-arrow -left'>  

              <span class='shaft'></span>
            </span>
            <span class='main'>
              <span class='text'>
              <span>&#8203;</span>
              </span>
              <span class='the-arrow -right'>
                <span class='shaft'></span>
              </span>
            </span>
          </Link>

           </div></CardText></Link> 

                   </Card >
                   

                 </Col>
                 </Row>
                 <br></br>
                   <br></br>
                </div>
               
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default Alerts;
