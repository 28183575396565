
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect ,useLocation} from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

import Index from "views/Index.js";
import Landing from "views/examples/Landing.js";
import Login from "views/examples/Login.js";
import Profile from "views/examples/Profile.js";
import Contactus from "views/examples/Contactus.js";
import ApplicationDevelopment from "views/IndexSections/ApplicationDevelopment";
import Bigdata from "views/IndexSections/Bigdata";
import Ai from "views/IndexSections/Ai";
import Productdevelopment from "views/IndexSections/Productdevelopment";
import Offshore from "views/IndexSections/Offshore";
import Backend from "views/IndexSections/Backend";
import Frontend from "views/IndexSections/Frontend";
import Mobile from "views/IndexSections/Mobile";
import Customers from "views/IndexSections/Customers";
import Vision from "views/IndexSections/Vision";
import Leadership from "views/IndexSections/Leadership";
import Aboutus from "views/IndexSections/Aboutus";
import { useLayoutEffect } from 'react';



const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
  <Wrapper>
    <Switch>
      <Route path="/" exact render={(props) => <Index {...props} />} />
      
      <Route
        path="/landing-page"
        exact
        render={(props) => <Landing {...props} />}
      />
      <Route
        path="/login-page"
        exact
        render={(props) => <Login {...props} />}
      />
      <Route
        path="/profile-page"
        exact
        render={(props) => <Profile {...props} />}
      />
      <Route
        path="/contact-us"
        exact
        render={(props) => <Contactus {...props} />}
      />
      <Route
        path="/application-engineering"
        exact
        render={(props) => <ApplicationDevelopment {...props} />}
      />
      <Route
        path="/bigdata-analytics"
        
        render={() => <Bigdata  />}
      />
      <Route
        path="/coversational-ai"
        exact
        render={(props) => <Ai {...props} />}
      />
       <Route
        path="/product-development-team"
        exact
        render={(props) => <Productdevelopment {...props} />}
      />
      <Route
        path="/offshore-development-center"
        exact
        render={(props) => <Offshore {...props} />}
      />
      <Route
        path="/backend-developer"
        exact
        render={(props) => <Backend {...props} />}
      />
      <Route
        path="/front-developer"
        exact
        render={(props) => <Frontend {...props} />}
      />
      <Route
        path="/mobile-application"
        exact
        render={(props) => <Mobile {...props} />}
      />
      <Route
        path="/customers"
        exact
        render={(props) => <Customers {...props} />}
      />
      <Route
        path="/vision"
        exact
        render={(props) => <Vision {...props} />}
      />
      <Route
        path="/leadership"
        exact
        render={(props) => <Leadership {...props} />}
      />
      <Route
        path="/about-us"
        exact
        render={(props) => <Aboutus {...props} />}
      />
      <Redirect to="/" />
    </Switch>
    </Wrapper>
  </BrowserRouter>
);
