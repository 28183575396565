import React from "react";
import { Helmet } from 'react-helmet';

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import Hero2 from "./Hero2.js";
//section 2

class ApplicationDevelopment extends React.Component {
 



  render() {
    return (
      <>
       <Helmet>
        <title>Application Development</title>
        <meta name="description" content="This is a description of my page." />
      </Helmet>
             <DemoNavbar />
             <Hero2 />
        <section
          className="section section-components pb-0"
          id="section-components"
        >
           <Container style={{marginTop:'5%'}}>
            <Row className="justify-content-center">
              <Col lg="12">
                {/* section 2*/}
                <h2 className=" font-weight-bold text-dark mb-3 mt-5 " >
                  <span style={{fontSize:'40px' }}>Microservices</span>
                </h2>
                <div>
                  <Row>
                  <Col sm="8">
                  <p style={{fontSize:'20px' }}>  Microservices are an architectural approach where a single application is broken down into smaller, modular services. Adopting microservices can help organizations improve flexibility, scalability, and agility.

                  </p>
                  <p>Example, monolithic e-commerce application into a set of microservices
                  </p>
                  {/* <Button className="btn mb-5" color="primary">Read More</Button> */}
                  </Col>
                  <Col  sm="4">
                  <img
                      alt="..."
                      className="sectiononeimg img-fluid "
                      src={require("assets/img/brand/section1.png")}
                    
                    />
                  </Col>
                  
                  </Row>
                  
                </div>
               
              </Col>
            </Row>
            </Container >
            </section>
            <section
          className="section section-components pb-0 secheight"
          id="section-components"
          style={{backgroundColor:'#f0fff4' }}
        >
                    <Container style={{marginTop:'5%'}}>

            <Row className="justify-content-center">
              <Col lg="12">
                {/* section 2*/}
                
                <div>
                  <Row>
                  <Col  sm="4">
                  <img
                      alt="..."
                      className="sectiontwoimg img-fluid  mt-5"
                      src={require("assets/img/brand/ai2.png")}
                    
                    />
                  </Col>
                  <Col sm="8">
                  <h2 className=" font-weight-bold text-dark  mb-3 mt-5 " >
                  <span style={{fontSize:'40px' }}>API
                      </span>
                </h2>
                  <p style={{fontSize:'20px' }}>  APIs are used to connect different applications and systems. API modernization can help organizations improve flexibility, scalability, and security.
                  </p>
                  <p>Example, SOAP-based APIs to RESTful APIs
</p>
                  {/* <Button className="btn" color="primary">Read More</Button> */}
                  </Col>
                
                  
                  </Row>
                  
                </div>
               
              </Col>
            </Row>
            </Container>
            </section>
            <section
          className="section section-components pb-0 secheight"
          id="section-components"
        >
                    <Container style={{marginTop:'5%'}}>
            <Row className="justify-content-center">
              <Col lg="12">
                {/* section 2*/}
                <h2 className=" font-weight-bold text-dark mb-3 mt-5 " >
                  <span style={{fontSize:'40px' }}>Legacy Modernization
                     </span>
                </h2>
                <div>
                  <Row>
                  <Col sm="8">
                  <p style={{fontSize:'20px' }}>  Legacy applications that were built using outdated technologies or languages can be modernized to take advantage of modern tools and platforms. 
                  </p>
                  <p>Example, Modernize its legacy COBOL-based accounting application to a modern web / mobile using Java</p>
                  {/* <Button className="btn" color="primary">Read More</Button> */}
                  </Col>
                  <Col  sm="4">
                  <img
                      alt="..."
                      className="sectiononeimg img-fluid "
                      src={require("assets/img/brand/ai.png")}
                    
                    />
                  </Col>
                  
                  </Row>
                  
                </div>
               
              </Col>
            </Row>
         
            </Container>
        </section>
        <section
          className="section section-components pb-0 secheight"
          id="section-components"
          style={{backgroundColor:'#f0fff4' }}
        >
                    <Container style={{marginTop:'5%'}}>

            <Row className="justify-content-center">
              <Col lg="12">
                {/* section 2*/}
                
                <div>
                  <Row>
                  <Col  sm="4">
                  <img
                      alt="..."
                      className="sectiontwoimg img-fluid  mt-5"
                      src={require("assets/img/brand/ai2.png")}
                    
                    />
                  </Col>
                  <Col sm="8">
                  <h2 className=" font-weight-bold text-dark  mb-3 mt-5 " >
                  <span style={{fontSize:'40px' }}>Containerization
                      </span>
                </h2>
                  <p style={{fontSize:'20px' }}>  Containerization is a method of packaging software into self-contained units that can run consistently across different environments. Containerization can help organizations improve portability, scalability, and deployment speed. 
                  </p>
                  <p>Example, Convert from Legacy apps to Kubernetes cluster

                         </p>
                  {/* <Button className="btn" color="primary">Read More</Button> */}
                  </Col>
                
                  
                  </Row>
                  
                </div>
               
              </Col>
            </Row>
            </Container>
            </section>
            <section
          className="section section-components pb-0 secheight"
          id="section-components"
        >
                    <Container style={{marginTop:'5%'}}>
            <Row className="justify-content-center">
              <Col lg="12">
                {/* section 2*/}
                <h2 className=" font-weight-bold text-dark mb-3 mt-5 " >
                  <span style={{fontSize:'40px' }}>Cloud Migration

                     </span>
                </h2>
                <div>
                  <Row>
                  <Col sm="8">
                  <p style={{fontSize:'20px' }}> Many organizations are migrating their legacy applications to cloud platforms like AWS, Azure, or Google Cloud. Cloud migration can help organizations reduce costs, increase scalability, and improve flexibility. 
                  </p>
                  <p>Example, From AWS to Google
                     </p>
                  {/* <Button className="btn" color="primary">Read More</Button> */}
                  </Col>
                  <Col  sm="4">
                  <img
                      alt="..."
                      className="sectiononeimg img-fluid "
                      src={require("assets/img/brand/ai.png")}
                    
                    />
                  </Col>
                  
                  </Row>
                  
                </div>
               
              </Col>
            </Row>
         
            </Container>
        </section>
        <CardsFooter />

      </>
    );
  }
}

export default ApplicationDevelopment;
