import React from "react";

// reactstrap components
import { Col,Row,Container,Card,CardTitle,CardText,Button } from "reactstrap";


//section 4

class Menus extends React.Component {
  render() {
    return (
      <>
         <section
          className="section section-components pb-0"
          id="section-components"
        >
          <div  style={{backgroundColor:'rgba(237, 237, 237, 0.49)',padding:'40px'  }}>
            <Row className="justify-content-center">
              <Col lg="12">
                {/* section 4*/}
                <h2 className=" font-weight-bold  mb-3 " style={{textAlign:"center" }}>
                  <span>A dispatch system trusted by 500+ transportation companies</span>
                </h2>
                <p className="text-primary  mb-5" style={{textAlign:'center' }}>
                If you seek to change the world as an iconic taxi service provider, we seek to change your world by helping you execute your dispatch operations at breakneck speed. TaxiMobility takes technology into new directions providing one-of-a-kind taxi dispatch system supported by high precision attributes.
                </p>
              
               </Col>
            </Row>
            <Row>
              <Col >
              <h2 className=" font-weight-bold  mb-3 " style={{textAlign:"center" }}>
                  <span> 
                   Predictive Analytics</span>
                </h2>
                <p className="text-primary  mb-5" style={{textAlign:'center' }}>
                  <small>
                Know your upcoming demand in advance with the technology that has been aided with Artificial Intelligence
                </small>
                </p>
              </Col>
              <Col>
              <h2 className=" font-weight-bold  mb-3 " style={{textAlign:"center" }}>
                  <span> 
                   
                    Business Growth</span>
                </h2>
                <p className="text-primary  mb-5" style={{textAlign:'center' }}>
                <small>
                Make you move forward in a realistic and prudent manner by realizing and understanding your business challenges
                </small>
                </p>
              </Col>
              <Col>
              <h2 className=" font-weight-bold  mb-3 "style={{textAlign:"center" }}>
                  <span> 
                  Faster Deployment</span>
                </h2>
                <p className="text-primary  mb-5" style={{textAlign:'center' }}>
                  <small>
                Get on board with ease and instantly through cloud deployment and let your growth curve begin at a faster pace
                </small>
                </p>
              </Col>
              <Col>
              <h2 className=" font-weight-bold  mb-3 " style={{textAlign:"center" }}>
                  <span> 
                  Marketing Support</span>
                </h2>
                
                <p className="text-primary  mb-5" style={{textAlign:'center' }}>
                <small>
                Reach your potential customers with our intensive digital support and through our inbuilt marketing features
                </small>
                </p>
              </Col>
            </Row>
            </div>
        </section>
      </>
    );
  }
}

export default Menus;
